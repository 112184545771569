@import './variables';

.title {
  color: $white;
  
}

.tokens {
  display: grid;
  grid-template-columns: repeat(auto-fit, 210px);
  gap: 16px;
  margin-bottom: 32px;
  width: 100%;
  justify-content: center;
}

.duelLevelRange {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  color: $white;
  font-size: 1.4em;
  margin-bottom: 24px;
}

.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  color: $white;
  font-size: 2em;
  margin-top: 16px;
}

.token {

  transition: transform $trans;
  display: block;
  will-change: transform;
  border-radius: 6px;
  overflow: hidden;

  &:hover {
    transform: scale(1.01);
  }
}

.joinButton {
  width: 100%;
  margin-top: 8px;
}